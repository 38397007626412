<template>

<section>
                <p class="t-center">
                <img class="iatalker" src="../assets/images/ia-talker.png?1" alt="Carita de robot blanca en forma de nube de mensaje y ojos negros con puntos verdes"/>
                </p>

                <audio src="../assets/audio/introduce.mp3" ref="audio"></audio>
                <div class="bubble">
                    
                    <a @click="pauseAudio" v-if="isplaying" class="pause-audio"><img width="40" src="../assets/images/pause-blue.png" alt="Botón de pausa"/></a>
                    <a @click="playAudio" v-if="!isplaying" class="play-audio"><img width="40" src="../assets/images/play-blue.png" alt="Botón de reproducción"/></a>
                

                    <div class="preloader" v-if="preloading">

                        <span class="circle1 circle"></span>
                        <span class="circle2 circle"></span>
                        <span class="circle3 circle"></span>


                    </div>
                    
                    <div class="ia-text" v-if="!preloading">
                        <span v-if="elapsed(0)">Hello, </span> 
                        <span v-if="elapsed(.5)">thank you </span> 
                        <span v-if="elapsed(.9)">for </span> 
                        <span v-if="elapsed(1.1)">coming in </span>
                        <span v-if="elapsed(1.3)">today. </span> 
                        <span v-if="elapsed(2.7)">Could </span> 
                        <span v-if="elapsed(2.8)">you </span> 
                        <span v-if="elapsed(3)">please </span> 
                        <span v-if="elapsed(3.5)">tell </span>
                        <span v-if="elapsed(3.7)">me </span> 
                        <span v-if="elapsed(3.9)">a </span> 
                        <span v-if="elapsed(4)">little </span> 
                        <span v-if="elapsed(4.2)">bit about </span> 
                        <span v-if="elapsed(4.6)">yourself </span> 
                        <span v-if="elapsed(5.2)">and </span> 
                        <span v-if="elapsed(5.5)">why </span> 
                        <span v-if="elapsed(5.7)">you're </span> 
                        <span v-if="elapsed(6)">interested </span> 
                        <span v-if="elapsed(6.3)">in </span> 
                        <span v-if="elapsed(6.4)">this </span> 
                        <span v-if="elapsed(6.6)">position? </span>
                    </div>

                
                </div>

</section>

</template>

<script>

export default{

    name:"InteractiveTalker",
    props:{


    },

    methods:{


        playAudio(){

            this.preloading=false;
            this.$refs.audio.play();
            this.isplaying=true;

        },

        pauseAudio(){
            
            this.$refs.audio.pause();
            this.isplaying=false;
        }


        

    },


    computed:{

        elapsed(){
            return function(el){
                
                return el<this.elapsedtime?true:false;
            }
        }


    },

    data(){

            return {
                    elapsedtime:0,
                    preloading:true,
                    isplaying:false
                    
                    }

    },


        mounted(){


            var that=this;
            this.$refs.audio.addEventListener("timeupdate",function(){
                that.elapsedtime=that.$refs.audio.currentTime;
            });


            this.$refs.audio.addEventListener("ended",function(){
                that.isplaying=false;
            });

}



}



</script>
