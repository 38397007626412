<template>
  <div id="app">
    
    <div class="container main-content">
      <router-view></router-view>
    </div>

    <footer>
    <p class="copyright">&copy; 2023 IATalker by <a href="https://iatalker.zeinsoftware.com">Zein Software</a></p>
    </footer>
  </div>



</template>

<script>


export default {
  name: 'App',
  components: {
    
    
  }
}
</script>

<style>




@font-face {
    font-family: 'Anta';
    src: url('./assets/fonts/anta/Anta-Regular.woff2') format('woff2'),
        url('./assets/fonts/anta/Anta-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.container{min-height:85vh}

.copyright{color:#fff;font-size:14px;text-align: center;padding-top:20px}
.copyright a{color:#fff}
.copyright a:hover{color:#fff}


.button1,.button2{cursor:pointer;padding:15px;font-size:1rem;display:inline-block;color:#fff;border-radius:10px;text-decoration:none !important;border:0}
.button1{background:#2CE1FB}
.button2{background:#0AA9D8;}
.button1:hover,.button2:hover{color:#fff}
.button1.disabled,.button2.disabled{cursor:default !important;opacity:.6}


body{background:#2CE1FB !important}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
