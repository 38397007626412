import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from './components/HomeView.vue'
import ResetPassword from './components/ResetPassword.vue'



Vue.use(VueRouter)


const router = new VueRouter({
  base:'/',
  routes:[
    { path: '/', component: HomeView },
    { path: '/reset-password', component: ResetPassword },
    { path: '/reset-password/:token', component: ResetPassword }
  ]
})

export default router
