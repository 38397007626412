<template>
    <section class="light-theme">

        <form @submit.prevent="resetPassword">

            <div class="t-center">
                <img src="../assets/images/ia-talker.png" width="250" alt="Carita de IATalker, tiene forma de burbuja de mensaje con dos ojos negros con pupila azul"/>
            </div>
            <h3 class="t-center">Cambiar contraseña</h3>
            <div class="form-group">
                <label>Contraseña nueva</label>
                <input v-bind:type="type_password" v-model="new_password" class="form-control" placeholder="Ingresa tu nueva contraseña"/>

                <svg
                v-if="type_password=='password'"
                @click="type_password='text'"
                fill="none"
                height="15"
                viewBox="0 0 10.999972 6"
                width="22"
                >
                
                <path
                    d="m 5.4999975,0 c -2.71293,0 -4.7198,1.7931 -5.4,2.7 -0.13333,0.1778 -0.13333,0.4222 0,0.6 0.6802,0.9069 2.68707,2.7 5.4,2.7 2.7129,0 4.7197995,-1.7931 5.3999995,-2.7 0.1333,-0.1778 0.1333,-0.4222 0,-0.6 C 10.219797,1.7931 8.2128975,0 5.4999975,0 Z m -1.8744,4.6543 C 2.5092975,4.2396 1.6427575,3.5335 1.1517675,3 c 0.49099,-0.5335 1.35753,-1.2396 2.47383,-1.6543 -0.38934,0.4408 -0.6256,1.02 -0.6256,1.6543 0,0.6343 0.23626,1.2135 0.6256,1.6543 z m 3.7488,0 c 1.1163,-0.4147 1.9828,-1.1208 2.4738,-1.6543 -0.491,-0.5335 -1.3575,-1.2396 -2.4738,-1.6543 0.3893,0.4408 0.6256,1.02 0.6256,1.6543 0,0.6343 -0.2363,1.2135 -0.6256,1.6543 z M 3.9999975,3 c 0,-0.8284 0.6716,-1.5 1.5,-1.5 0.8284,0 1.5,0.6716 1.5,1.5 0,0.8284 -0.6716,1.5 -1.5,1.5 -0.8284,0 -1.5,-0.6716 -1.5,-1.5 z"
                    id="path10"
                    style="clip-rule:evenodd;fill:#2CE1FB;fill-rule:evenodd" />
                </svg>


                <svg
                v-if="type_password=='text'"
                @click="type_password='password'"
                fill="none"
                height="16"
                viewBox="0 0 10.999972 8.2322931"
                width="25"
                >

                <path
                    d="m 5.4999975,1.0652995 c -2.71293,0 -4.7198,1.7931 -5.4,2.7 -0.13333,0.1778 -0.13333,0.4222 0,0.6 0.6802,0.9069 2.68707,2.7 5.4,2.7 2.7129,0 4.7197995,-1.7931 5.3999995,-2.7 0.1333,-0.1778 0.1333,-0.4222 0,-0.6 -0.6802,-0.9069 -2.6870995,-2.7 -5.3999995,-2.7 z m -1.8744,4.6543 c -1.1163,-0.4147 -1.98284,-1.1208 -2.47383,-1.6543 0.49099,-0.5335 1.35753,-1.2396 2.47383,-1.6543 -0.38934,0.4408 -0.6256,1.02 -0.6256,1.6543 0,0.6343 0.23626,1.2135 0.6256,1.6543 z m 3.7488,0 c 1.1163,-0.4147 1.9828,-1.1208 2.4738,-1.6543 -0.491,-0.5335 -1.3575,-1.2396 -2.4738,-1.6543 0.3893,0.4408 0.6256,1.02 0.6256,1.6543 0,0.6343 -0.2363,1.2135 -0.6256,1.6543 z m -3.3744,-1.6543 c 0,-0.8284 0.6716,-1.5 1.5,-1.5 0.8284,0 1.5,0.6716 1.5,1.5 0,0.8284 -0.6716,1.5 -1.5,1.5 -0.8284,0 -1.5,-0.6716 -1.5,-1.5 z"
                    id="path10"
                    style="clip-rule:evenodd;fill:#2CE1FB;fill-rule:evenodd" />
                <rect
                    style="fill:#2CE1FB;fill-opacity:1;stroke-width:1.23071;paint-order:stroke fill markers"
                    id="rect271"
                    width="1.6271187"
                    height="11.18644"
                    x="6.0413494"
                    y="-6.70543"
                    ry="1.0653753"
                    transform="rotate(45.565922)" />
                </svg>


            </div>

            <div class="form-group">
                <label>Repetir contraseña</label>
                <input v-bind:type="type_repeat" v-model="repeat_password" class="form-control" placeholder="Repite la nueva contraseña"/>
                <svg
                v-if="type_repeat=='password'"
                @click="type_repeat='text'"
                fill="none"
                height="15"
                viewBox="0 0 10.999972 6"
                width="22"
                >
                
                <path
                    d="m 5.4999975,0 c -2.71293,0 -4.7198,1.7931 -5.4,2.7 -0.13333,0.1778 -0.13333,0.4222 0,0.6 0.6802,0.9069 2.68707,2.7 5.4,2.7 2.7129,0 4.7197995,-1.7931 5.3999995,-2.7 0.1333,-0.1778 0.1333,-0.4222 0,-0.6 C 10.219797,1.7931 8.2128975,0 5.4999975,0 Z m -1.8744,4.6543 C 2.5092975,4.2396 1.6427575,3.5335 1.1517675,3 c 0.49099,-0.5335 1.35753,-1.2396 2.47383,-1.6543 -0.38934,0.4408 -0.6256,1.02 -0.6256,1.6543 0,0.6343 0.23626,1.2135 0.6256,1.6543 z m 3.7488,0 c 1.1163,-0.4147 1.9828,-1.1208 2.4738,-1.6543 -0.491,-0.5335 -1.3575,-1.2396 -2.4738,-1.6543 0.3893,0.4408 0.6256,1.02 0.6256,1.6543 0,0.6343 -0.2363,1.2135 -0.6256,1.6543 z M 3.9999975,3 c 0,-0.8284 0.6716,-1.5 1.5,-1.5 0.8284,0 1.5,0.6716 1.5,1.5 0,0.8284 -0.6716,1.5 -1.5,1.5 -0.8284,0 -1.5,-0.6716 -1.5,-1.5 z"
                    id="path10"
                    style="clip-rule:evenodd;fill:#2CE1FB;fill-rule:evenodd" 
                    
                    />
                </svg>


                <svg
                v-if="type_repeat=='text'"
                @click="type_repeat='password'"
                fill="none"
                height="16"
                viewBox="0 0 10.999972 8.2322931"
                width="25"
                >

                <path
                    d="m 5.4999975,1.0652995 c -2.71293,0 -4.7198,1.7931 -5.4,2.7 -0.13333,0.1778 -0.13333,0.4222 0,0.6 0.6802,0.9069 2.68707,2.7 5.4,2.7 2.7129,0 4.7197995,-1.7931 5.3999995,-2.7 0.1333,-0.1778 0.1333,-0.4222 0,-0.6 -0.6802,-0.9069 -2.6870995,-2.7 -5.3999995,-2.7 z m -1.8744,4.6543 c -1.1163,-0.4147 -1.98284,-1.1208 -2.47383,-1.6543 0.49099,-0.5335 1.35753,-1.2396 2.47383,-1.6543 -0.38934,0.4408 -0.6256,1.02 -0.6256,1.6543 0,0.6343 0.23626,1.2135 0.6256,1.6543 z m 3.7488,0 c 1.1163,-0.4147 1.9828,-1.1208 2.4738,-1.6543 -0.491,-0.5335 -1.3575,-1.2396 -2.4738,-1.6543 0.3893,0.4408 0.6256,1.02 0.6256,1.6543 0,0.6343 -0.2363,1.2135 -0.6256,1.6543 z m -3.3744,-1.6543 c 0,-0.8284 0.6716,-1.5 1.5,-1.5 0.8284,0 1.5,0.6716 1.5,1.5 0,0.8284 -0.6716,1.5 -1.5,1.5 -0.8284,0 -1.5,-0.6716 -1.5,-1.5 z"
                    id="path10"
                    style="clip-rule:evenodd;fill:#2CE1FB;fill-rule:evenodd" />
                <rect
                    style="fill:#2CE1FB;fill-opacity:1;stroke-width:1.23071;paint-order:stroke fill markers"
                    id="rect271"
                    width="1.6271187"
                    height="11.18644"
                    x="6.0413494"
                    y="-6.70543"
                    ry="1.0653753"
                    transform="rotate(45.565922)" />
                </svg>



            </div>


            <ul class="requirements">

                <li v-bind:class="min_length?'done':''">Debe tener como mínimo 8 caracteres</li>
                <li v-bind:class="lower_upper?'done':''">Debe contener letras mayúsculas y minúsculas</li>
                <li v-bind:class="has_number?'done':''">Debe contener al menos un número</li>
                <li v-bind:class="has_special_char?'done':''" ref="rule1">Debe contener al menos un caracter especial</li>


            </ul>

            <p class="t-center reset-button">
                <button class="button1" v-bind:class="loading?'disabled':''">Cambiar contraseña     <b-spinner v-if="loading" label="Enviando..." small></b-spinner></button>
            </p>

        </form>


    <div class="successfully" v-if="done">


        <div class="done-content">


            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50" height="50">
            <path fill="none" d="M0 0h24v24H0z"/>
            <path d="M9 16.17L5.83 13l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
            </svg>

            <h3>Tu contraseña fue cambiada con éxito</h3>

            <br>
            <p>Ahora puedes usar tu nueva contraseña para iniciar sesión en tu app de IATalker para Android</p>

            <br>
            <a href="" class="button1">Ir a la aplicación</a>

        </div>

    </div>

    </section>
    
</template>

<script>


import axios from 'axios';



export default {

    name:"ResetPassword",
    props:{},
    data(){

        return {

            valid:false,
            type_password:'password',
            type_repeat:'password',
            new_password:'',
            repeat_password:'',
            loading:false,
            done:false
        }
    },

    computed:{

        min_length(){

            return this.new_password.length>=8;

        },

        has_special_char(){
            
            return /[^\w\s|_]/.test(this.new_password);
        },
        has_number(){
            return /[0-9]/.test(this.new_password);
        },
        lower_upper(){
            return /(?=.*[a-z])(?=.*[A-Z])/.test(this.new_password);
        }
    },


    methods:{


        match(){




            return this.new_password==this.repeat_password

        },
        
        resetPassword(){


            if(this.match()){

                if(this.min_length && this.has_special_char && this.has_number && this.lower_upper)
                {



                    this.loading=true;

                    const data={
                        token:this.token,
                        password:this.new_password
                    }
                        var that=this;
                    
                    axios.post('https://api.artesaniasmerida.com/api/v1/auth/resetpassword',data)
                        .then(response => {
                            if(response.data.done){
                                that.done=true;
                            }else{
                                alert(response.data.message);
                            }
                        })
                        .catch((e)=> {
                            
                            alert('No es posible cambiar la contraseña ahora: '+e.response.data.message);
                        }).finally(()=>{

                            this.loading=false;

                        });

                    
                }
                else{
                    alert("La contraseña no es segura");
                }
            }else{
                alert("La contraseña no coincide");
            }

    }

    },

    mounted(){

        this.token=this.$route.query.token;

    }

}
</script>


<style scoped>

    
    .light-theme{background:#D1F6FF;padding:50px;max-width:700px;margin:0 auto;border-radius:10px}

    form{margin:0 auto;}
    h3{color:#0AA9D8;margin:20px 0}
    input {background:#fff;border:0;padding:30px !important;border-radius:10px}

    .t-center{text-align:center}

    .requirements li{font-size:.8rem;line-height:1;margin:5px}

    .reset-button{margin-top:50px}

    .form-group{position:relative;}
    .form-group svg{position:absolute;right:10px;top:55px;}

    ::placeholder{color:#999}


    .requirements li.done{color:#52b152}

    .button1.disabled{opacity:.5;pointer-events: none;}


    .successfully{position:fixed;background:rgba(179, 175, 175, 0.8);top:0;left:0;width:100%;height:100%;z-index:10;display:flex;align-items:center;justify-content: center;}


    .successfully svg{fill:#52b152}
    .done-content{background:#fff;padding:50px;max-width:500px;}
    .done-content,.done-content h3,.done-content p{text-align:center !important}
    .done-content p{color:#000}


</style>