<template>

<section>



<div class="row">





    <div class="col-md-6">

        <h2>IAtalker</h2>

        <h3>Practica tu inglés hablado conversando con la IA</h3>


        
        

        <div class="d-block d-sm-none">


            <InteractiveTalker/>
            

        </div>



        <p class="download-it">¡Descarga la app y comienza gratis!</p>
        <a href=""><img class="google-play" src="../assets/images/google-play.png" alt="Botón GET IT on Google Play oficial de google"/></a>





    </div>    


    <div class="col-md-6 d-none d-md-block">

        <InteractiveTalker/>


    </div>




</div>


    
</section>



</template>

<script>



    import InteractiveTalker from "./InteractiveTalker.vue";


    export default{

        name:"HomeView",
        components:{
            InteractiveTalker
                
        }

    }


</script>


<style>


*{text-align: left;font-family: "Open Sans",sans-serif;}

@media(max-width:768px){

    img{max-width: 100% !important;}
    .iatalker{margin-top:20px}
    img.iatalker{max-width:90% !important}
}

img{max-width:100%;}
h2{font-family: Anta, sans-serif;color:#E4FAFF;font-weight:bold;font-size:100px;}
h3{color:#fff;font-size:2rem;}
p{color:#fff;font-size:1.5rem;}
.t-center{text-align: center;}
.download-it{margin-top:100px}

.iatalker{max-width:450px}

.google-play{max-width:400px}

.bubble{ background:#fff;padding:20px;border-radius:40px;max-width:450px;margin:0 auto;min-height:150px;position:relative;}

.play-audio,.pause-audio{float:left;position:absolute;top:15px;right:15px;color:#000;cursor:pointer}

.ia-text{font-size:1.4rem;padding-right:50px}

.preloader{position:absolute;top:50%;margin-top:-20px;width:100%;text-align: center;left:0}
.preloader .circle{border-radius: 100%;background:#d0f7fe;width:20px;height:20px;display:inline-block;margin:10px;}


.circle1{animation:loading 1.5s ease infinite;}
.circle2{animation:loading 1.5s ease infinite;animation-delay:.5s}
.circle3{animation:loading 1.5s ease infinite;animation-delay:1s}

@keyframes loading{

    0%{
        transform:scale(1)
    }
    50%{
        transform:scale(1.5)
    }
    100%{
        transform:scale(1)
    }

}

</style>